body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.btn-primary {
  color: #FFF !important;
  background-color: #0657A1 !important;
  border-color: #0657A1 !important;
}

.btn-secondary {
  color: #FFF !important;
  background-color: #2C3D46 !important;
  border-color: #2C3D46 !important;
}

.btn-danger {
  color: #FFF !important;
  background-color: #D80132 !important;
  border-color: #D80132 !important;
}

.btn-warning {
  color: #FFF !important;
  background-color: #EE811E !important;
  border-color: #EE811E !important;
}

.btn-outline-warning {
  color: #EE811E !important;
  background-color: transparent !important;
  border-color: #EE811E !important;
}

.btn-outline-warning:hover {
  color: #FFF !important;
  background-color: #EE811E !important;
  border-color: #EE811E !important;
}

.btn-outline-warning:focus,
.btn-outline-warning.focus {
  box-shadow: 0 0 0 0.2rem rgba(238, 129, 30, 0.5) !important;
}

.text-warning {
  color: #EE811E !important;
  border-color: #EE811E !important;
}

.bg-warning {
  color: #fff !important;
  border-color: #EE811E !important;
  background-color: #EE811E !important;
}

.border-warning {
  border-color: #EE811E !important;
}

.text-danger {
  font-size: 15px;
  color: #D80132 !important;
  border-color: #D80132 !important;
}

.text-primary {
  color: #0657A1 !important;
  border-color: #0657A1 !important;
}

.thead-warning {
  font-size: 15px;
  color: #FFF;
  background-color: #EE811E !important;
  border-color: #EE811E !important;
}

.form-control:focus {
  outline: none !important;
  box-shadow: none !important;
  border-color: inherit;
}